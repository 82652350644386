<template>
  <svg
    width="9"
    height="16"
    viewBox="0 0 9 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.76473 8.53021C9.06749 8.21832 9.06749 7.78168 8.82528 7.46979L1.68029 0.233918C1.37753 -0.0779727 0.893125 -0.0779727 0.650921 0.233918L0.227066 0.670565C-0.0756885 0.982456 -0.0756885 1.4191 0.227066 1.73099L6.40325 7.96881L0.227066 14.269C-0.0756885 14.5809 -0.0756885 15.0175 0.227066 15.3294L0.650921 15.7661C0.893125 16.078 1.37753 16.078 1.68029 15.7661L8.76473 8.53021Z"
      fill="currentColor"
    />
  </svg>
</template>
